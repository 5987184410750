"use strict";
import React from "react";
import styles from "./Footer.module.css";

export function Footer() {
    return (
        <footer className={styles.Footer}>
            <div>
                <p>Mindful Web Studio AB | &copy; {new Date().getFullYear()}</p>
            </div>
        </footer>
    );
}