"use strict";
import React, { useState, useEffect } from "react";
import { FrameLeftNavbar, FrameTop, FrameRight, FrameBottom } from "../components/Header/Header";
import { AboveTheFold } from '../components/AboveTheFold/AboveTheFold';
import { PackageDiv } from '../components/Packages/Packages';
import { BookingDiv } from "../components/Booking/Booking";
import { AboutDiv } from "../components/About/About";
import { SneakpeekCaseDiv } from "../components/Cases/SneakpeekCases";
import { Footer } from "../components/Footer/Footer";

export default function HomePage() {
    const [backgroundColor, setBackgroundColor] = useState('#349891ff'); // Wavy Teal
    const [textColor, setTextColor] = useState('#fff');

    useEffect(() => {
        const handleScroll = () => {
            //console.log(window.scrollY); // Kolla scrollY position
            if (window.scrollY >= 2700) {
                // Ändra bakrundsfärg och textfärg om scroll är över 2700px
                // Sektion Om oss
                setBackgroundColor('#349891ff'); // Wavy Teal
                setTextColor('#c9ffb8'); // Fresh Start Mint
            } else if (window.scrollY >= 1890) {
                // Ändra bakrundsfärg och textfärg om scroll är över 1890px
                // Sektion Case
                setBackgroundColor('#1c4587ff'); // Confident Navy
                setTextColor('#c9ffb8'); // Fresh Start Mint
            } else if (window.scrollY >= 1000) {
                // Ändra bakrundsfärg och textfärg om scroll är över 1000px
                // Sektion Boka
                setBackgroundColor('#ffad83ff'); // Calm Peach
                setTextColor('#1c4587ff'); // Confident Navy
            } else if (window.scrollY >= 250) {
                // Ändra bakrundsfärg och textfärg om scroll är över 250px
                // Sektion Paket
                setBackgroundColor('#1c4587ff'); // Confident Navy
                setTextColor('#c9ffb8'); // Fresh Start Mint
            } else {
                // Återställ färg om scroll är mindre än 250px
                // Sektion AboveThefold
                setBackgroundColor('#349891ff'); // Wavy Teal
                setTextColor('#fff');
            }
        };

        // Lägg till eventlistener för scroll
        window.addEventListener('scroll', handleScroll);

        // Städa upp eventlistner när komponenten unmountas
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <div className="container">
            <FrameTop
                page="Home"
            />
            <FrameLeftNavbar
                page="Home"
            />
            <FrameRight />
            <FrameBottom />
            <AboveTheFold
                page="Home"
                backgroundColor={backgroundColor}
            />
            <main className="main">
                <PackageDiv
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
                <BookingDiv
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
                <SneakpeekCaseDiv
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
                <AboutDiv
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
            </main>
            <Footer />
        </div>
    );
}