"use strict";
import React from "react";
import styles from "./Header.module.css";

export function FrameTop({ page }) {
    return (
        <div className={styles.FrameTop}>
            <a href="./" className={styles.logo}>
                <img
                    src={page === "Home" ? "assets/logo_primary_black.svg" : "../assets/logo_primary_black.svg"}
                    alt="Mindful Web Studio Logo"
                />
            </a>
        </div>
    );
}

export function FrameLeftNavbar({ page }) {
    return (
        <nav className={styles.FrameLeftNavbar}>
            {(page === "Home") && (
                <ul>
                    <li>
                        <a href="./">Hem</a>
                    </li>
                    <li>
                        <a href="#packageDiv">Paket</a>
                    </li>
                    <li>
                        <a href="#bookingDiv">Boka</a>
                    </li>
                    <li>
                        <a href="#CaseDiv">Case</a>
                    </li>
                    <li>
                        <a href="#AboutDiv">Om</a>
                    </li>
                </ul>
            )}
                <ul>
                    <li>
                        <a href="../">Hem</a>
                    </li>
                </ul>
        </nav>
    );
}

export function FrameRight() {
    return (
        <div className={styles.FrameRight}>
            <p>Mindful Web Studio</p>
        </div>
    );
}

export function FrameBottom() {
    return (
        <div className={styles.FrameBottom}>

        </div>
    );
}