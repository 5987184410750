"use strict";
import React from "react";
import styles from "./AboveTheFold.module.css";

export function AboveTheFold({ page, caseNumber, backgroundColor }) {
    return (
        <div
            className={styles.AboveTheFold}
            style={{
                backgroundColor: backgroundColor,
            }}
        >
            <div className={styles.content}>
            {/* Visa specifikt innehåll beroende på om det är hem eller en case-sida */}
            {page === "Home" ? (
                <>
                    <h1>Vi levererar stressfri väg till digital identitet och konkurrenskraft</h1>
                    <h2>Inte din vanliga webbyrå: remote-first, paketlösningar, och ett Mindful fokus</h2>
                </>
            ) : (
                <h1>Detta är case {caseNumber}</h1>
            )}
            </div>
        </div>
    );
}