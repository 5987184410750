"use strict";
import React, { useState, useRef, useEffect } from "react";
import { CalendarDiv } from "./Calendar";
import { sendFormDataToServer } from "../../services/apiServices";
import styles from "./Booking.module.css";
import { format } from 'date-fns';
import { sv as localeSv } from 'date-fns/locale';

export function BookingDiv({ backgroundColor, textColor }) {
    // State för att lagra formulärvärden
    const [formData, setFormData] = useState({
        kontaktuppgifter1: "",
        kontaktuppgifter2: "",
        date: null,
        time: "",
        email: ""
    })

    // Definiera referenser till varje input-fält
    const kontaktuppgifter1Ref = useRef(null);
    const kontaktuppgifter2Ref = useRef(null);
    const kontaktuppgifter4Ref = useRef(null);
    const datePickerRef = useRef(null); // Skapa en referens för att hantera fokus till datumdiven

    // State för servermeddelande
    const [serverMessage, setServerMessage] = useState('');

    // State för att spåra vilket steg användaren är på (börjar på steg 1)
    const [currentStep, setCurrentStep] = useState(1);

    // Hantera formulärinmatningen
    const handleInputChange = function(e) {
        const { name, value } = e.target;
        setFormData({
            ...formData, // Bevara övriga värden i formData
            [name]: value // Uppdatera endast den nyckeln som gäller.
        });
    }

    // Hantera datum och tid som kommer från CalendarDiv
    const handleDateChange = function(selectedDate) {
        // const formatDate = format(selectedDate, "EEEE MMMM dd yyyy", { locale: localeSv });
        const formatDate = format(selectedDate, "EEEE dd-MM-yyyy", { locale: localeSv });
        setFormData({
            ...formData, // Bevara övriga värden i formData
            date: formatDate
        });
    }
    const handleTimeChange = function(selectedTime) {
        setFormData({
            ...formData,
            time: selectedTime
        });
    }

    // Funktion för att kontrollera att alla fält i formData är ifyllda.
    const validateFormData = function(formData) {
        const isValid = Object.values(formData).every(function(value) {
            return value && value.trim() !== ""; // Kontrollera att värdet finns och inte är ett tomt mellanslag
        })
        return isValid; // Returnerar true om alla fält är giltiga annars false.
    }

    // Den här funktionen hanterar när formuläret skickas, formuläret skickas till serversidan för vidare hantering
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateFormData(formData)) {
            const message = "Vänligen fyll i alla fält, tack!";
            setServerMessage(message);
        } else {
            setServerMessage('Bokningen har skickats. Väntar på bekräftelsesvar..');
            try {
                const message = await sendFormDataToServer(formData);
                setServerMessage(message);
            } catch (error) {
                setServerMessage("Något gick fel. Försök igen om en stund!");
            }
        }
    }

    useEffect(() => {
        // Sätter fokus på det första inputfältet i formuläret utan att scrolla ner på sidan!
        if (kontaktuppgifter1Ref.current) {
            kontaktuppgifter1Ref.current.focus({ preventScroll: true });
        }
    }, []);

    // Funktion som hanterar "Nästa"-knappen
    function handleNext(nextFieldRef) {
        // Öka steget med 1 för att gå till nästa div
        setCurrentStep(prevStep => prevStep + 1);
        if (nextFieldRef && nextFieldRef.current) {
            nextFieldRef.current.focus(); // Fokusera på nästa fält
        }
    }

    const handleStepClick = (stepNumber) => {
        // Endast tillåtet att gå till tidigare steg eller till det specifika steget användaren klickar på
        if (stepNumber <= currentStep) {
            setCurrentStep(stepNumber); // Gå till steget användaren klickat på
        }
    }

    return (
        <section
            id="bookingDiv"
            className={styles.BookingDiv}
            style={{
                backgroundColor: backgroundColor,
                color: textColor
            }}>
            <div className={styles.Wrapper}>
                <div className={styles.bookingInfo}>
                    <h1>Boka ett Kickoff-Samtal!</h1>
                    <p>Ett digitalt möte på cirka 15 minuter, där vi tillsammans utforskar hur vi kan hjälpa er att förverkliga er företagsidé. Oavsett om ni är i startgroparna eller bara nyfikna på våra tjänster, är detta möte ett utmärkt tillfälle för oss att se om vi är rätt partner för ert projekt.</p>
                    <h3>Har ni frågor eller vill komma i kontakt med oss? Skicka gärna ett mejl - vi ser fram emot att höra från er!</h3>
                    <p>Epost: info@mindfulwebstudio.se</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div
                        className={`${styles.Collapse} ${currentStep === 1 ? styles.open : styles.hidden}`}
                        onClick={() => handleStepClick(1)}
                    >
                        <h2>01 Fullständigt namn</h2>
                        <input
                            type="text"
                            name="kontaktuppgifter1"
                            placeholder="Namn"
                            ref={kontaktuppgifter1Ref}
                            value={FormData.kontaktuppgifter1}
                            onChange={handleInputChange}
                        />
                        <button
                            type="button"
                            className={styles.next}
                            onClick={(e) => {
                                e.stopPropagation(); // Förhindrar att "onClick" på diven triggas
                                handleNext(kontaktuppgifter2Ref); // Vidare till nästa steg
                            }}
                        >
                            Nästa
                        </button>
                    </div>
                    <hr></hr>
                    <div
                        className={`${styles.Collapse} ${currentStep === 2 ? styles.open : styles.hidden}`}
                        onClick={() => handleStepClick(2)}
                    >
                        <h2>02 Beskrivning av företaget</h2>
                        <textarea
                            placeholder="Beskriv kort vad ert företag gör eller vilka tjänster ni är intresserade av, så att vi kan hjälpa er på bästa möjliga sätt."
                            name="kontaktuppgifter2"
                            ref={kontaktuppgifter2Ref}
                            value={FormData.kontaktuppgifter2}
                            onChange={handleInputChange}
                        />
                        <button
                            type="button"
                            className={styles.next}
                            onClick={(e) => {
                                e.stopPropagation(); // Förhindrar att "onClick" på diven triggas
                                handleNext(datePickerRef); // Vidare till nästa steg
                            }}
                        >
                            Nästa
                        </button>
                    </div>
                    <hr></hr>
                    <div
                        className={`${styles.Collapse} ${currentStep === 3 ? styles.open : styles.hidden}`}
                        onClick={() => handleStepClick(3)}
                    >
                        <h2>03 Datum och tid</h2>
                        <CalendarDiv
                            onDateChange={handleDateChange}
                            onTimeChange={handleTimeChange}
                            ref={datePickerRef} // Ge referensen till CalendarDiv
                        />
                        <button
                            type="button"
                            className={styles.next}
                            onClick={(e) => {
                                e.stopPropagation(); // Förhindrar att "onClick" på diven triggas
                                handleNext(kontaktuppgifter4Ref); // Gå vidare till nästa steg
                            }}
                        >
                            Nästa
                        </button>
                    </div>
                    <hr></hr>
                    <div
                        className={`${styles.Collapse} ${currentStep === 4 ? styles.open : styles.hidden}`}
                        onClick={() => handleStepClick(4)}
                    >
                        <h2>04 E-postadress</h2>
                        <input
                            type="email"
                            name="email"
                            placeholder="E-post"
                            ref={kontaktuppgifter4Ref}
                            value={FormData.email}
                            onChange={handleInputChange}
                        />
                        <input
                            type="submit"
                            value="Skicka"
                            className={styles.next}
                        />
                        {/* Visa meddelandet för användaren */}
                        {serverMessage && <p>{serverMessage}</p>}
                    </div>
                </form>
            </div>
        </section>
    );
}