"use strict";
import React from "react";
import styles from "./CaseGallery.module.css";

export function CaseGalleryDiv({ caseNumber }) {
    return (
        <section className={styles.CaseGallery}>
            <div className={styles.Wrapper}>
                <div>
                    <img src="#" alt="Detta är case bild nummer 1"/>
                </div>
                <div>
                    <h1>Detta är Case nummer {caseNumber}!</h1>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                </div>
                <div>
                    <img src="#" alt="Detta är case bild nummer 1"/>
                </div>
                <div>
                    <img src="#" alt="Detta är case bild nummer 1"/>
                </div>
                <div>
                    <img src="#" alt="Detta är case bild nummer 1"/>
                </div>
                <div>
                    <img src="#" alt="Detta är case bild nummer 1"/>
                </div>
                <div>
                    <h1>Detta är Case nummer {caseNumber}!</h1>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                </div>
            </div>
        </section>
    );
}