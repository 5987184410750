"use strict";
import React from "react";
import styles from "./Packages.module.css";

export function PackageDiv({ backgroundColor, textColor }) {
    return (
        <section
            id="packageDiv"
            className={styles.Packages}
            style={{
                backgroundColor: backgroundColor,
            }}>
            <div className={styles.Wrapper}>
                <div>
                    <h1 style={{color: textColor}}>01</h1>
                    <h2 style={{color: textColor}}>Komplett Startpaket</h2>
                    <p>Ta steget in i den digitala världen med ett komplett paket som bygger er varumärkesidentitet från grunden. Vårt startpaket ger er allt ni behöver för att sticka ut online - från en professionellt designad webbsida till en skräddarsydd grafisk profil. Perfekt för företag som vill skapa en stark, enhetlig närvaro på webben och fånga sina kunders uppmärksamhet. Paketet innehåller:</p>
                    <ul>
                        <li>En mobilanpassad och SEO-optimerad webbplats</li>
                        <li>Användarvänlig webbdesign</li>
                        <li>Grafisk profil inklusive logotyp, färgpalett, typografi och grafiska riktlinjer</li>
                        <li>Uppföljning och support efter projekt</li>
                    </ul>
                </div>
                <div>
                    <h1 style={{color: textColor}}>02</h1>
                    <h2 style={{color: textColor}}>Visuell identitet-paket</h2>
                    <p>Ge ert varumärke en visuell identitet som sticker ut och skapar intryck. Vårt grafiska profilpaket ger er en tydlig och professionell grund för all er kommunikation - från färger och typsnitt till en unik logotyp som speglar er vision. Paketet innehåller:</p>
                    <ul>
                        <li>Skräddarsydd logotyp</li>
                        <li>Färgpalett</li>
                        <li>Typografi</li>
                        <li>Grafiska riktlinjer</li>
                        <li>Uppföljning efter projekt</li>
                    </ul>
                </div>
                <div>
                    <h1 style={{color: textColor}}>03</h1>
                    <h2 style={{color: textColor}}>Webbplats-paket</h2>
                    <p>Få en webbsida som gör intryck och levererar resultat. Med vårt webbsidespaket hjälper vi er att skapa en snygg, användarvänlig och mobilanpassad webbsida som speglar ert varumärke och engagerar era kunder -  oavsett om ni behöver en helt ny webbsida eller vill fräscha upp en befintlig webbsida.</p>
                    <ul>
                        <li>En mobilanpassad och SEO-optimerad webbplats</li>
                        <li>Användarvänlig webbdesign</li>
                        <li>CMS eller helt från grunden</li>
                        <li>Uppföljning och support efter projekt</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}