"use strict";
import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { FrameLeftNavbar, FrameTop, FrameRight, FrameBottom } from "../components/Header/Header";
import { AboveTheFold } from '../components/AboveTheFold/AboveTheFold';
import { CaseReportDiv } from "../components/Cases/CaseReport";
import { CaseGalleryDiv } from "../components/Cases/CaseGallery";
import { Footer } from "../components/Footer/Footer";

export default function CasePage() {
    const { caseNumber } = useParams();

    // ------- VITKIGT!!! Denna fungerar inte ännu. Se över!
    useEffect(() => {
        console.log('useEffect körs, skrollar till toppen');
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container">
            <FrameTop />
            <FrameLeftNavbar />
            <FrameRight />
            <FrameBottom />
            <AboveTheFold
                caseNumber={caseNumber}
            />
            <main className="main">
                <CaseReportDiv
                    caseNumber={caseNumber}
                />
                <CaseGalleryDiv
                    caseNumber={caseNumber}
                />
                {/* Lägg in kod här för att gå till nästa case */}
            </main>
            <Footer />
        </div>
    );
}