"use strict";
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CasePage from './pages/CasePage';
import CommingSoon from './pages/CommingSoon';

export default function App() {
    return(
        <Router>
            <Routes>
                <Route path='/' element={<CommingSoon />} /> {/* VIKTIGT!!! VID LANSERING, ÄNDRA DENNA TILL HOMEPAGE */}
                <Route path='/prototyp' element={<HomePage />} />
                <Route path='/case/:caseNumber' element={<CasePage />} />
            </Routes>
        </Router>
    );
}