"use strict";
import React from "react";
import styles from "./About.module.css";
import mindful from "../../assets/mindful.jpg";
import oss from "../../assets/oss.jpg";

export function AboutDiv({ backgroundColor, textColor }) {
    return (
        <section
            id="AboutDiv"
            className={styles.About}
            style={{
                backgroundColor: backgroundColor,
            }}
            >
            <div className={styles.Wrapper}>
                <div className={styles.innerWrap}>
                    <div>
                        <h1 style={{color: textColor}}>Varför Mindful?</h1>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                    </div>
                    <img id={styles.imgOfMindful} src={mindful} alt="Bild på.."/>
                </div>
                <div className={styles.innerWrap}>
                    <div>
                        <h1 style={{color: textColor}}>Vilka är vi?</h1>
                        <p>Mindful Web Studio AB består av Karoline Lindroos och Johanna Christensson. Vi har båda en kandidatexamen inom medieteknik: ett program med bred kompetens att förstå och driva hela produktionsflödet för webb- och designprojekt. Karoline Lindroos har senare specialiserad erfarenhet inom webbutveckling samt drivit webbprojekt genom sin enskilda firma sedan 2023. Johanna Christensson har erfarenhet inom kommunikation och grafisk formgivare inom civilsamhället och SEO-byrå genom en enskild firma sedan 2017.</p>
                    </div>
                    <img id={styles.imgOfUs} src={oss} alt="Bild på.."/>
                </div>
            </div>
        </section>
    )
}