"use strict";
import React, { useState, useEffect, useRef, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { getAvailableTimes } from "../../services/apiServices";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { sv } from 'date-fns/locale/sv';
registerLocale('sv', sv);

// Omslut funktionen med forwardRef som gör att jag kan vidarbefodra en ref från en föräldrakomponent till ett specifikt element inom CalendarDiv
export const CalendarDiv = forwardRef(({onDateChange, onTimeChange}, ref) => {
  const [startDate, setStartDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const availableTimes = [
    "8:00", "8:15", "8:30", "8:45",
    "9:00", "9:15", "9:30", "9:45",
    "10:00", "10:15", "10:30", "10:45",
    "11:00", "11:15", "11:30", "11:45",
    "12:00", "12:15", "12:30", "12:45",
    "13:00", "13:15", "13:30", "13:45",
    "14:00", "14:15", "14:30", "14:45"
  ];

  /* FORTSÄTT MED DENNA IFALL VI FÅR MASSOR AV KUNDER OCH DET BLIR SVÅRT ATT HÅLLA REDA PÅ LEDIGA TIDER */
  // const fetchAvailableTimes = async (selectedDate) => {
  //   try {
  //     const message = await getAvailableTimes(selectedDate);
  //     console.log(message);
  //   } catch (error) {
  //     console.error("Error fetching available times: ", error);
  //   }
  // }

  // Skapa en referens till DatePicker-komponenten
  const datePickerRef = useRef(null);

  // När användaren väljer ett datum
  const handleDateChange = function(date) {
    setStartDate(date); // Uppdatera valt datum
    onDateChange(date); // Skickar datumet till den överordnade komponenten

    /* FORTSÄTT MED DENNA IFALL VI FÅR MASSOR AV KUNDER OCH DET BLIR SVÅRT ATT HÅLLA REDA PÅ LEDIGA TIDER */
    // const formattedDate = date.toISOString().split('T')[0];
    // fetchAvailableTimes(formattedDate);
  }

  // När användaren väljer en tid
  const handleTimeChange = function(e) {
    const time = e.target.value;
    setSelectedTime(time);
    onTimeChange(time); // Skickar tiden till den överordnade komponenten
  };

  // Idag och 2 dagar framåt
  const today = new Date();
  const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 2); // 2 dagar framåt

  // Funktion för att hitta närmsta bokningsbara dag som inte är en lördag eller söndag
  const getNextAvailableWeekday = function(date) {
    let nextDate = new Date(date);
    while (nextDate.getDay() === 0 || nextDate.getDay() === 6) { // 0 = Söndag, 6 = Lördag
      nextDate.setDate(nextDate.getDate() + 1); // Flytta framåt tills det är en vardag
    }
    return nextDate;
  };

  // Nästa bokningsbara vardag
  const firstAvailableDate = getNextAvailableWeekday(minDate);

  // useEffect som skickar första lediga datum när komponenten laddas
  useEffect(() => {
    if (!startDate) {
      onDateChange(firstAvailableDate);
    }
  }, []);

  return (
    <div style={{
      display: "flex",
      gap: "0.5em",
      flexDirection: "column"
    }}>
      {/* Datepicker för att välja datum */}
      {/* <label htmlFor="dateSelect">Välj ett datum:</label> */}
      <DatePicker
        id="dateSelect"
        ref={datePickerRef} // Tilldela referensen
        selected={startDate || firstAvailableDate} // Förvalt första vardag efter minDat
        onChange={handleDateChange}
        locale="sv"
        dateFormat="yyyy-MM-dd"
        placeholderText="Välj ett datum"
        filterDate={(date) => {
          const day = date.getDay(); // Hämta veckodagen
          // Inaktivera söndagar (0), lördagar (6), och dagar före idag och inom 2 dagar framåt
          return day !== 0 && day !== 6 && date >= minDate;
        }}
        renderDayContents={(day, date) => {
          const isToday =
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
          return (
            <div
              style={{
                backgroundColor: isToday ? '#faebd7' : 'transparent', // Markera dagens datum
                borderRadius: '50%',
                color: date.getDay() === 6 || date.getDay() === 0 ? 'red' : '', // Färga helgdagar röda
              }}>
              {day}
            </div>
          );
        }}
      />
      {/* Dropdown för tillgängliga tider */}
      {(startDate || firstAvailableDate) && (
        <div>
          {/* <label htmlFor="timeSelect">Välj en tid:</label> */}
          <select
            id="timeSelect"
            onChange={handleTimeChange}
            style={{
              color: "#fff",
              backgroundColor: "#1c4587ff",
              border: "none",
            }}
          >
            <option value="">Välj tid</option>
            {availableTimes.length > 0 ? ( // En check på om listans med de lediga tiderna är större än 0, annars generera en option disabled "Inga tider tillgängliga"
              availableTimes.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))
            ) : (
              <option disabled>Inga tider tillgängliga</option>
            )}
          </select>
        </div>
      )}
    </div>
  );
});