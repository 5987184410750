"use strict";
import React from "react";
import styles from "../styles/commingSoon.module.css";

export default function CommingSoon() {
    return (
        <section className={styles.CommingSoon}>
            <div className={styles.Wrapper}>
                <h1>Kommer snart!</h1>
                <h2>Stay tuned!</h2>
            </div>
        </section>
    );
}