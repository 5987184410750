"use strict";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SneakpeekCases.module.css";

export function SneakpeekCaseDiv({ backgroundColor, textColor }) {
    const [visibleCase, setVisibleCase] = useState(1); // Startar med case 1
    const navigate = useNavigate();

    const handleVisibleCase = (caseNumber) => {
        setVisibleCase(caseNumber);
    }

    const handleNavigate = (caseNumber) => {
        navigate(`/case/${caseNumber}`);
    }

    return (
        <section
            id="CaseDiv"
            className={styles.Cases}
            style={{backgroundColor: backgroundColor}}
        >
            <h1 style={{color: textColor}}>Case</h1>
            <div className={styles.Wrapper}>
                <div>
                    <p
                        onClick={() => handleVisibleCase(1)}
                        style={{
                            color: visibleCase === 1 ? 'black' : 'gray'
                        }}
                    >
                        01
                    </p>
                    <p
                        onClick={() => handleVisibleCase(2)}
                        style={{
                            color: visibleCase === 2 ? 'black' : 'gray'
                        }}
                    >
                        02
                    </p>
                    <p
                        onClick={() => handleVisibleCase(3)}
                        style={{
                            color: visibleCase === 3 ? 'black' : 'gray'
                        }}
                    >
                        03
                    </p>
                </div>
                {visibleCase === 1 && (
                    <div>
                        <h2>Case 1</h2>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</p>
                        <button onClick={() => handleNavigate(1)}>Visa Case</button>
                    </div>
                )}
                {visibleCase === 2 && (
                    <div>
                        <h2>Case 2</h2>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</p>
                        <button onClick={() => handleNavigate(2)}>Visa Case</button>
                    </div>
                )}
                {visibleCase === 3 && (
                    <div>
                        <h2>Case 3</h2>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</p>
                        <button onClick={() => handleNavigate(3)}>Visa Case</button>
                    </div>
                )}
            </div>
        </section>
    );
}