"use strict";

export async function sendFormDataToServer(formData) {
    try {
        const res = await fetch('http://localhost:7777/api/new-booking', { // ----------- VIKTIGT!!! Denna ska ändras sen till endpoint mindfulwebstudio.se
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
        if (res.ok) {
            const message = await res.text(); // Svar från servern
            return message;
            // console.log('Svar från servern:', message);
        } else {
            const message = "Något gick fel, Försök igen om en stund!";
            return message;
            //console.log("Något gick fel!");
        }
    } catch (error) {
        console.error('Error: ', error);
    }
}

/* IFALL VI FÅR MASSOR AV BOKNINGAR SÅ KAN JAG FORTSÄTTA MED DENNA DEL DÅ, FOR NOW, HÄMTA TILLGÄNGLIGA TIDER FRÅN FRÅN EN HÅRDKODAD LISTA
export async function getAvailableTimes(date) {
    console.log("funktionen getavailabletimes försöker köras");
    // ----- FORTSÄTT HÄR, FUNDERA IFALL EN GET REQUEST SKA GÖRAS VARJE GÅNG ANVÄNDAREN KLICKAR PÅ ETT NYTT DATUM I KALENDERN ELLER OM DET SKA LADDAS IN FÖR EN VECKA OCH SPARAS I LOCALSTORAGE?!?
    try {
        const res = await fetch('http://localhost:7777/api/get-available-time', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        if (res.ok) {
            console.log('Svar från servern:', res.text());
        } else {
            console.log("Något gick fel!");
        }

    } catch (error) {
        console.error('Error: ', error);
    }
}
*/